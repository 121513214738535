<template>
  <section class="tender-details main-wrapper">
    <div class="main-content">
      <header-vue />
      <bread-crumbs :breadcrumbItems="breadcrumbItems" />

      <div class="container text-center-mobile-only pb-1 pl-5 pr-5 pt-5 mb-4">
        <div
          class="
            is-flex is-align-items-center is-justify-content-space-between
            mb-4
          "
        >
          <h2
            class="
              main-title
              is-size-3-5-mobile
              has-text-left has-text-centered-mobile
              mr-4
            "
          >
            Регламент
          </h2>
        </div>
      </div>
      <div class="container pb-1 pl-5 pr-5 mb-4">
        <div class="rules-container p-6 mt-5">
          <div class="rules-content">
            <p>
              Закупки проводятся в соответствии с внутренним стандартом ООО
              «ЕВРАЗ Стил Билдинг» «Управление закупками».
            </p>
            <p>
              В зависимости от сложности категории, конъюнктуры рынка сроки
              проведения выбора поставщика могут отличаться, и могут составлять
              от 5 рабочих дней до месяца.
            </p>
            <div class="border-section">
              <p>
                К торгам допускаются аккредитованные участники. Конкурс
                проводится при наличии не менее трех предложений. На этапе
                анализа коммерческих предложений от участников и
                <b
                  >при выборе победителя конкурса специалисты общества
                  руководствуются следующими критериями:</b
                >
              </p>
              <ul>
                <li>качество производимых ТМЦ, работ/услуг</li>
                <li>цена</li>
                <li>порядок оплаты</li>
                <li>срок поставки ТМЦ или выполнения работ/услуг</li>
                <li>гарантийный период</li>
                <li>рейтинг контрагента</li>
              </ul>
              <h4>
                Рейтинг контрагента формируется на базе следующих критериев:
              </h4>
              <ul>
                <li>производитель/дилер</li>
                <li>срок существования организации</li>
                <li>
                  лояльность и клиентоориентированность (оценка по результатам
                  сотрудничества с ООО «ЕВРАЗ Стил Билдинг»)
                </li>
                <li>
                  соблюдение сроков поставки (оценка по результатам
                  сотрудничества с ООО «ЕВРАЗ Стил Билдинг»)
                </li>
                <li>
                  наличие/отсутствие претензий (оценка по результатам
                  сотрудничества с ООО «ЕВРАЗ Стил Билдинг»)
                </li>
              </ul>
            </div>
            <p>
              Таким образом в основе системы рейтингования поставщиков лежит
              принцип стимулирования поставщиков к продолжительной и
              положительной истории сотрудничества, на базе которой повышается
              их рейтинг и шансы на победу в торгах.
            </p>
            <div>
              По результатам проведения тендера каждый участник получает
              обратную связь в виде сообщения с портала о закрытии процедуры и
              занятой позицией в конкурсе.
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-vue />
  </section>
</template>

<script>
import HeaderVue from "@/components/common/Header.vue";
import FooterVue from "@/components/common/Footer.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";

export default {
  components: {
    HeaderVue,
    FooterVue,
    BreadCrumbs,
  },
  name: "Rules",
  computed: {
    breadcrumbItems() {
      return [
        {
          title: "Регламент",
          link: null,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.rules-container {
  box-shadow: 5px 10px 50px 0px rgba(175, 175, 175, 0.25);
  background: url(../assets/img/rules-img.svg) no-repeat right top 50px;
}
.rules-content {
  max-width: 43em;
  color: #323232;
  font-size: 18px;
  ul {
    margin-bottom: 25px;
    li {
      position: relative;
      padding-left: 20px;
      &::before {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background: linear-gradient(94.43deg, #a2165b 0%, #5a004b 100%);
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 9px;
      }
    }
  }
  h4 {
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.rules-content p {
  margin-bottom: 25px;
}

.border-section {
  border: 1px solid #e4e4e4;
  margin-bottom: 25px;
  padding: 30px;
  ul:last-of-type {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1500px) {
  .rules-container {
    background-position: right -5em top 3.5em;
  }
}

@media screen and (max-width: 1200px) {
  .rules-container {
    background: none;
  }
}

@media screen and (max-width: 768px) {
  .rules-container,
  .border-section {
    padding: 15px !important;
    font-size: 16px;
  }
}
</style>